import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import { MapOptions, tileLayer, latLng, marker, divIcon, map } from 'leaflet';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet-rotatedmarker';
import { LayersService } from '@shared/services/layers.service';
import { StateService } from '@shared/services/state.service';

@Component({
  selector: 'app-viewpoint-map',
  templateUrl: './viewpoint-map.component.html',
  styleUrls: ['./viewpoint-map.component.scss'],
})
export class ViewpointMapComponent implements OnInit {
  @Input('mode') mode: string = 'proposed';
  @Input('viewpoint') viewpoint: any;
  @Input('viewpoints') viewpoints: any[];
  @Input('isMinimized') isMinimized: boolean = false;
  @Input('zoom') zoom: number = 15;
  @Input('minZoom') minZoom: number = 18;
  @Input('maxZoom') maxZoom: number = 10;

  currentMarker: any;
  options: MapOptions;
  markers: marker[];
  yaw: number = 0;
  // yawOffset: number = 0;
  isReady: boolean = false;
  layersControl: any;
  lMap: map;
  private OVERLAY_NAME = 'Proposed';

  constructor(
    private router: Router,
    private zone: NgZone,
    private viewpointsService: ViewpointsService,
    private layersService: LayersService
  ) {
    this.viewpointsService.onChangeMode.subscribe(() => {
      // this.state = { ...this.state, ...state };
      this.toggleOverlay(this.OVERLAY_NAME);
    });
    // console.log('layers control: ', this.layersControl);
    this.viewpointsService.onUpdateViewpoint.subscribe((viewpoint) => {
      this.update(viewpoint);
    });
  }

  ngOnInit(): void {
    if (!this.viewpoint) {
      throw new Error('No viewpoint provided');
    }
    if (!this.viewpoint.coords) {
      throw new Error('No coords provided');
    }
    let that = this;
    setTimeout(function () {
      that.layersControl = {
        overlays: that.layersService.getOverlays(),
      };
      if (that.mode === 'proposed') {
        that.viewpointsService.toggleMode();
      }
    }, 500);

    this.options = {
      layers: [
        tileLayer(
          // for a street map instead, use this:
          //'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          {
            attribution: '',
            // limiting he field of view in the map
            maxZoom: this.minZoom,
            minZoom: this.maxZoom,
          }
        ),
      ],
      zoomControl: false,
      zoom: this.zoom,
      center: latLng(this.viewpoint.coords.lat, this.viewpoint.coords.lng),
    };
    let markers = [];
    if (this.viewpoints) {
      for (let i = 0; i < this.viewpoints.length; i++) {
        const viewpoint = this.viewpoints[i];
        let newMarker = this.getMarker(viewpoint);
        markers.push(newMarker);
      }
    }
    this.markers = markers;
  }

  getHeading(yaw) {
    return (yaw * 180.0) / Math.PI;
  }

  update(viewpoint) {
    let heading = this.getHeading(viewpoint.yaw);
    if (viewpoint.yawOffset) {
      heading = heading + viewpoint.yawOffset;
    }
    this.currentMarker.setRotationAngle(heading);
  }

  getMarker(viewpoint) {
    // Using divIcon so can apply theme color (from css variable)
    const customIcon = divIcon({
      className: 'custom-marker',
      iconAnchor: [30.5, 30.5],
      html: this.getMarkerHTML(viewpoint),
    });
    let heading = this.getHeading(viewpoint.yaw);
    if (viewpoint.yawOffset) {
      heading = heading + viewpoint.yawOffset ;//this.getHeading(viewpoint.yawOffset);
    }
    let newMarker = marker([viewpoint.coords.lat, viewpoint.coords.lng], {
      rotationAngle: heading,
      // rotationAngle: (viewpoint.yaw * 180.0) / Math.PI + this.yawOffset,
      icon: customIcon,
      title: viewpoint.title,
    });
    newMarker.on('click', () => {
      this.zone.run(() => {
        this.router.navigateByUrl(`/viewpoint?name=${viewpoint.slug}`);
      });
    });
    if (viewpoint.slug === this.viewpoint.slug) {
      this.currentMarker = newMarker;
    }
    return newMarker;
  }

  onMapReady(map: map) {
    this.lMap = map;
    let that = this;
    window.setTimeout(function () {
      // need to call invalidateSize for Leaflet to resize map from original size
      map.invalidateSize();
      that.isReady = true;
    }, 0);
  }

  getMarkerHTML(viewpoint) {
    let markerSvgString = ``;
    if (!viewpoint.fovDegrees || viewpoint.fovDegrees === 360) {
      markerSvgString = `<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60.9661 30.9996C60.9661 47.5391 47.5502 60.9476 31.0003 60.9476C14.4504 60.9476 1.0345 47.5391 1.0345 30.9996C1.0345 14.4601 14.4504 1.0516 31.0003 1.0516C47.5502 1.0516 60.9661 14.4601 60.9661 30.9996Z" fill="black" fill-opacity="0.5" stroke="white"/>
      <path d="M11.3828 8.12348C17.0658 3.3576 24.2936 0.827948 31.7101 1.0091C39.1266 1.19025 46.2221 4.06975 51.6655 9.10739L30.9658 31.448L11.3828 8.12348Z" fill="currentColor" stroke="white"/>
      <path d="M30.8016 38.6623C34.9437 38.6623 38.3016 35.3044 38.3016 31.1623C38.3016 27.0202 34.9437 23.6623 30.8016 23.6623C26.6595 23.6623 23.3016 27.0202 23.3016 31.1623C23.3016 35.3044 26.6595 38.6623 30.8016 38.6623Z" fill="currentColor" stroke="white"/>
      </svg>
      
      `;
    } else if (viewpoint.fovDegrees === 124) {
      markerSvgString = `<svg width="63" height="62" viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.35086 16.2596C7.03411 11.4172 10.9867 7.39648 15.7838 4.62969C20.581 1.8629 26.0411 0.45467 31.5774 0.55638C37.1137 0.65809 42.5167 2.2659 47.2057 5.20696C51.8947 8.14803 55.6922 12.3111 58.1902 17.2487L31.0006 30.9994L4.35086 16.2596Z" fill="black" fill-opacity="0.5"/>
      <mask id="path-2-inside-1" fill="white">
      <path d="M11.3828 7.52347C17.0658 2.75759 24.2936 0.227934 31.7101 0.409083C39.1266 0.590232 46.2221 3.46974 51.6655 8.50738L30.9658 30.848L11.3828 7.52347Z"/>
      </mask>
      <path d="M11.3828 7.52347C17.0658 2.75759 24.2936 0.227934 31.7101 0.409083C39.1266 0.590232 46.2221 3.46974 51.6655 8.50738L30.9658 30.848L11.3828 7.52347Z" fill="currentColor" stroke="white" stroke-width="2" mask="url(#path-2-inside-1)"/>
      <circle cx="30.8016" cy="31.1623" r="7.5" fill="currentColor" stroke="white"/>
      </svg>
      `;
    } else if (viewpoint.fovDegrees === 84) {

      markerSvgString = `<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="white">
      <path d="M11.3828 7.5235C17.0658 2.75762 24.2936 0.227964 31.7101 0.409113C39.1266 0.590263 46.2221 3.46977 51.6655 8.50741L30.9658 30.848L11.3828 7.5235Z"/>
      </mask>
      <path d="M11.3828 7.5235C17.0658 2.75762 24.2936 0.227964 31.7101 0.409113C39.1266 0.590263 46.2221 3.46977 51.6655 8.50741L30.9658 30.848L11.3828 7.5235Z" fill="currentColor" stroke="white" stroke-width="2" mask="url(#path-1-inside-1)"/>
      <circle cx="30.8016" cy="31.1623" r="7.5" fill="currentColor" stroke="white"/>
      </svg>
      `;
    }
    return `<style>
    .custom-marker {
      color: var(--clr-secondary);
      position: absolute;
    }
    }</style>${markerSvgString}`;
  }

  toggleOverlay = function (overlayName) {
    const layer = this.layersControl['overlays'][overlayName];
    if (!layer) {
      return;
    }
    if (this.lMap.hasLayer(layer)) {
      this.lMap.removeLayer(layer);
    } else {
      this.lMap.addLayer(layer);
    }
  };
}
