<div class="horizontal-toolbar" [ngClass]="[justifyLeft ? 'justify-left' : '', isShow ? 'is-show' : '']">
  <span class="toolbar-content">
    <h3 *ngIf="title" [innerHTML]="title"></h3>
    <div class="items">
      <span *ngFor="let viewpoint of viewpoints" routerLink="/viewpoint" [queryParams]="{ name: viewpoint.slug }">
        <span
            *ngIf="!viewpoint.main"
            class="item numberplate" [ngClass]="
            currentViewpoint && viewpoint.slug === currentViewpoint.slug
              ? 'active'
              : ''
          ">
          <span [innerHTML]="viewpoint.roadsign.title"></span>
          <span class="position" [innerHTML]="viewpoint.roadsign.position"></span>
        </span>
      </span>
    </div>
  </span>
  <svg class="close-btn" (click)="toggle()" width="24" height="24" viewBox="0 0 24 24" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM17.6719 15.0938C17.9062 15.2812 17.9062 15.6562 17.6719 15.8906L15.8438 17.7188C15.6094 17.9531 15.2344 17.9531 15.0469 17.7188L12 14.625L8.90625 17.7188C8.71875 17.9531 8.34375 17.9531 8.10938 17.7188L6.28125 15.8438C6.04688 15.6562 6.04688 15.2812 6.28125 15.0469L9.375 12L6.28125 8.95312C6.04688 8.76562 6.04688 8.39062 6.28125 8.15625L8.15625 6.32812C8.34375 6.09375 8.71875 6.09375 8.95312 6.32812L12 9.375L15.0469 6.32812C15.2344 6.09375 15.6094 6.09375 15.8438 6.32812L17.6719 8.15625C17.9062 8.39062 17.9062 8.76562 17.6719 8.95312L14.625 12L17.6719 15.0938Z"
      fill="currentColor" />
  </svg>
</div>
<div class="horizontal-menu-toggle-btn" (click)="toggle()"
  [ngClass]="app.type === 'standalone' ? 'standalone' : 'embedded'">
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.876343 4.27552H20.3763C20.7513 4.27552 21.1263 3.94739 21.1263 3.52552V1.65052C21.1263 1.27552 20.7513 0.900518 20.3763 0.900518H0.876343C0.454468 0.900518 0.126343 1.27552 0.126343 1.65052V3.52552C0.126343 3.94739 0.454468 4.27552 0.876343 4.27552ZM0.876343 11.7755H20.3763C20.7513 11.7755 21.1263 11.4474 21.1263 11.0255V9.15052C21.1263 8.77552 20.7513 8.40052 20.3763 8.40052H0.876343C0.454468 8.40052 0.126343 8.77552 0.126343 9.15052V11.0255C0.126343 11.4474 0.454468 11.7755 0.876343 11.7755ZM0.876343 19.2755H20.3763C20.7513 19.2755 21.1263 18.9474 21.1263 18.5255V16.6505C21.1263 16.2755 20.7513 15.9005 20.3763 15.9005H0.876343C0.454468 15.9005 0.126343 16.2755 0.126343 16.6505V18.5255C0.126343 18.9474 0.454468 19.2755 0.876343 19.2755Z"
      fill="currentColor" />
  </svg>
</div>